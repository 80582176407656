import * as Sentry from '@sentry/browser';
import * as SentryVue from '@sentry/vue';

export function initSentry(Vue?: any) {
  // 開発環境の場合はスキップ
  if(import.meta.env.DEV) return;

  /** Sentryのオプション */
  const options: Sentry.BrowserOptions = {
    dsn: import.meta.env.SENTRY_DSN,
    environment: import.meta.env.RAILS_ENV,
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay()
    ],
    tracesSampleRate: 0.01,
    // リプレイのサンプリングレート：本番環境はなし、それ以外は10%
    replaysSessionSampleRate: isProd() ? 0 : 0.1,
    // エラーが発生した場合のリプレイのサンプリングレート：本番環境は50%、それ以外は0%
    replaysOnErrorSampleRate: isProd() ? 0.5 : 0,
    // 例：['agent.m3career.com'] とすると、agent.m3career.comを含むURLで発生したエラーのみをキャプチャする
    allowUrls: ['agent.m3career.com', 'googletagmanager.com']
  }

  if(Vue) {
    // Vue指定の場合はVue用の初期化を行う
    SentryVue.init({
      ...options,
      Vue,
      trackComponents: true
    });
  }
  else {
    // Vue未指定の場合はVanillaJS用の初期化を行う
    Sentry.init(options);
  }
}

function isProd(): boolean {
  return import.meta.env.RAILS_ENV === 'production'
}